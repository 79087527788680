@import "~antd/dist/antd.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

:root {
  --cold_grey: #f9f9fb;
  --primary-color: #faa535;
  --text-color: #58595b;
  --error: #e74c3c;
  --dark-blue: #001d3a;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography {
  color: var(--text-color);
}
::-moz-selection {
  color: #fff;
  background: var(--primary-color) !important;
}
::selection {
  color: #fff;
  background: var(--primary-color) !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: var(--primary-color);
}
.ant-menu-horizontal > .ant-menu-item > a:hover {
  color: var(--cold_grey);
}
.ant-menu-horizontal > .ant-menu-item:hover {
  border: none;
  border-bottom: none;
}
a {
  color: var(--primary-color);
  font-weight: 500;
}

.ant-menu-vertical .ant-menu-item > a {
  color: var(--primary-color);
  white-space: normal;
  width: 100%;
}
.ant-menu-vertical.ant-menu-sub {
  min-width: 200px;
}
.ant-btn-link {
  color: var(--error);
}
#root {
  background: var(--cold_grey);
}

#route {
  background: var(--cold_grey);
  max-width: 1200px;
  margin: auto;
  min-height: 95vh;
}
.logo {
  margin: auto 16px auto 0;
}
.logo img {
  width: 100%;
}
.ant-btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  border-radius: 12px;
}
.ant-btn:hover,
.ant-btn:focus {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: var(--cold_grey);
}
.ant-input-number:hover {
  border-color: var(--primary-color);
}
.ant-layout-header {
  height: auto;
  padding: 0;
  line-height: 50px;
  background: var(--cold_grey);
}

.ant-menu-dark {
  background: var(--dark-blue);
}

.ant-menu-horizontal {
  padding: 0 20px 0 0;
  border-radius: 12px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02),
    0 1px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08);
}

.ant-menu-vertical {
  border-radius: 12px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02),
    0 1px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08);
}
.ant-menu-vertical li:not(:last-child) {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 30px;
  line-height: 30px;
}

.ant-layout-sider {
  background: var(--cold_grey);
  z-index: 1090;
}

.ant-layout {
  background: var(--cold_grey);
}

.ant-layout-sider-children {
  height: auto;
  border-radius: 12px;
  background: #ffffff;
  margin-top: 24px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02),
    0 1px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08);
}

.ant-menu-inline {
  border-right: 1px solid #e8e8e8;
  border-radius: 12px;
}

.ant-menu-horizontal {
  border: none;
}

.headerMenu {
  max-width: 100%;
  margin: auto;
}

/* For demo */

.ant-carousel .slick-slide {
  text-align: center;
  height: 320px;
  line-height: 320px;
  background: #364d79;
  overflow: hidden;
}

.firstSlide div {
  position: absolute;
}

.ant-carousel .slick-slide h3 {
  font-size: 50px;
  font-weight: 700;
  color: #364d79;
}

.ant-carousel .slick-list {
  border-radius: 12px;
}

.firstSlide {
  background-size: cover;
  background-repeat: no-repeat;
}

.secondSlide {
  background-size: cover;
  background-repeat: no-repeat;
}

.thirdSlide {
  background-size: cover;
  background-repeat: no-repeat;
}

.logo {
  text-align: center;
}

.footerLogo img {
  width: 180px;
}

.catLogo {
  width: 140px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px auto;
  text-align: center;
}

.headerCartBreak {
  display: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--primary-color);
}
.ant-menu-item-selected {
  color: var(--primary-color);
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: var(--primary-color);
  border-bottom: none;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid var(--primary-color);
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: var(--primary-color);
}
span:hover {
  border-color: var(--primary-color) !important;
}
.calculator.ant-card {
  background: var(--dark-blue);
  color: var(--cold_grey);
  padding: 12px;
}
.calculator .ant-form-item-label > label {
  color: var(--cold_grey);
}
.ant-btn {
  border-radius: 12px;
}
.ant-input {
  border-radius: 12px;
}
.ant-input-number-lg {
  border-radius: 12px;
}
.ant-input-number-input {
  border-radius: 12px;
}
.ant-input-number-handler-wrap {
  border-radius: 0 12px 12px 0;
}
.ant-input:focus {
  border-color: var(--primary-color);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-input:hover {
  border-color: var(--primary-color);
  border-right-width: 1px !important;
}
.ant-card {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--text-color);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
  border-radius: 12px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08),
    0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.ant-card:hover {
  -webkit-box-shadow: 0 7px 14px rgba(0, 0, 0, 0.12),
    0 5px 5px rgba(0, 0, 0, 0.09);
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.12), 0 5px 5px rgba(0, 0, 0, 0.09);
}

.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: var(--text-color);
  font-weight: 700;
  font-size: 18px;
  background: transparent;
  border-bottom: 1px solid var(--cold_grey);
  border-radius: 2px 2px 0 0;
  zoom: 1;
}
.ant-card-head-title {
  display: inline-block;
  -ms-flex: 1;
  -webkit-box-flex: 1;
  flex: 1 1;
  padding: 12px 0;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.ant-pagination-item-active a {
  color: var(--primary-color);
}
.ant-pagination-item-active {
  border-color: var(--primary-color);
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: var(--primary-color);
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: var(--primary-color);
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: var(--primary-color);
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: var(--primary-color);
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.footer {
  padding: 16px 16px 0 16px;
  background: var(--dark-blue);
  color: var(--primary-color);
}
.footer .ant-col {
  padding: 24px;
}
.footerMenu {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
  list-style: none;
}
.footerMenu svg {
  margin-right: 6px;
}
.findUs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.findUs p {
  font-size: 16px;
  font-weight: 500;
  margin: auto 0;
}
.footerPhones {
  padding-right: 20px;
  text-align: left;
  display: -ms-grid;
  display: grid;
}

.footer .socialIcons svg {
  font-size: 36px;
  margin-right: 6px;
  color: white;
  max-width: 30px;
}

.fade-enter {
  opacity: 0;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.fade-appear {
  opacity: 0;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.fade-leave {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.fade-enter.fade-enter-active {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.fade-appear.fade-appear-active {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.fade-leave.fade-leave-active {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.bannerOne {
  height: 250px;
  line-height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background: transparent;
}
.bannerTwo {
  height: 250px;
  line-height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
}

.leftTowerBanner,
.rightTowerBanner,
.bottomBanner {
  position: relative;
  margin: 20px 0;
  border-radius: 12px;
  width: 100%;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02),
    0 1px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08);
}
.pageHeader {
  position: relative;
  margin: 20px 0;
  border-radius: 12px;
  width: 100%;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02),
    0 1px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08);
}
.image-gallery-swipe {
  border-radius: 12px;
}
.image-gallery-slides {
  border-radius: 12px;
}
.image-gallery-slide.center {
  border-radius: 12px;
}
.image-gallery-slide {
  border-radius: 12px;
}
.image-gallery-slide img {
  border-radius: 12px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02),
    0 1px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08);
}
.cartPrice {
  margin-left: 12px;
  font-weight: 700;
}
.totalPrice {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}
.totalPrice span {
  color: var(--error);
}
.additionalText {
  color: var(--error);
  text-align: center;
}
.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.1;
  -webkit-filter: blur(20px);
  filter: blur(20px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

h4.oldPrice {
  text-decoration: line-through;
}

.ant-typography + h2.ant-typography {
  margin-top: 0.2em;
  color: var(--primary-color);
}
.langSwitch {
  display: flex;
}
.langSwitch button {
  margin-left: 10px;
  display: inline-flex;
}
.langSwitch img {
  border-radius: 12px;
  margin-right: 6px;
  align-self: center;
}
.activeLanguage {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
#products .ant-card-body {
  padding: 12px 6px;
  width: 100%;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#products .ant-card {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 300px 50px;
  grid-template-rows: 300px 50px;
}

.ant-card-body {
  padding: 12px 6px;
}

.ant-card-meta-title {
  font-size: 14px;
  font-weight: 700;
  white-space: normal;
  background: #f9f9fbc7;
  color: #001d3a;
  width: 100%;
  left: 0;
  padding: 0px 12px;
  border-radius: 12px;
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.ant-card-meta-detail {
  display: inline-flex;
  position: absolute;
  bottom: 60px;
}
.ant-card-meta {
  -webkit-align-content: space-between;
  align-content: space-between;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}
.ant-card-cover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
#products .ant-card-cover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 250px;
}
#products.results .ant-card-cover {
  height: 300px;
}
#products .ant-card-cover img {
  border-radius: 2px 2px 0 0;
  margin: auto;
  vertical-align: middle;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
}
.ant-card-cover > * {
  display: block;
  width: 100%;
  padding: 12px;
}
.ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: var(--cold_grey);
  border-top: 1px solid #e8e8e8;
  zoom: 1;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 28px;
  cursor: pointer;
}
.ant-card-actions > li {
  float: left;
  margin: 12px auto;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.ant-card-actions > li:first-child > span {
  text-align: left;
  padding-left: 10px;
}
.ant-card-actions > li:last-child > span {
  text-align: right;
  padding-right: 10px;
}
.cardPrice {
  font-size: 15px;
  font-weight: 700;
  color: var(--primary-color);
}
.categoriesName {
  margin: 6px auto;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}
.ant-tag {
  font-size: 14px;
}
.ant-card .ant-tag {
  font-size: 8px;
}
.productTags {
  font-size: 12px;
}
iframe {
  width: 100%;
  border-radius: 12px;
  height: 315px;
}
.manufacturerLogoInProduct {
  position: absolute;
  top: 6px;
  left: 12px;
  max-width: 50px;
  z-index: 9;
  opacity: 0.8;
}
#products.results .manufacturerLogoInProduct {
  max-width: 80px;
}
.manufacturerLogoInProductPage {
  width: 70px;
}
.share_buttons {
  margin-top: 64px;
}
.share_buttons .ant-card-body {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.share_buttons svg {
  font-size: 32px;
  margin-right: 6px;
  cursor: pointer;
}
.ant-card .oldPrice {
  position: absolute;
  bottom: 12px;
  font-size: 12px;
  text-decoration: line-through;
  right: 12px;
}

.socialIcons {
  text-align: left;
  display: -ms-grid;
  display: grid;
}

.socialIcons svg {
  font-size: 24px;
  margin-right: 6px;
}
.socialIcons a {
  color: var(--primary-color);
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.socialIcons a p {
  margin: auto 0;
  font-weight: 500;
}
.ant-menu-dark .ant-menu-sub {
  background: #18324c;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
  width: 100%;
}
.carouselButton {
  position: absolute;
  top: 0px;
}
.resultForm {
  position: relative;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  font-size: 16px;
}
.stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.footerCol {
  border-right: 1px solid;
}
.headerSocialIcons {
  text-align: center;
}
.headerSocialIcons a {
  color: rgba(0, 0, 0, 0.65);
}
.headerSocialIcons svg {
  font-size: 24px;
  margin-right: 6px;
}
.ant-carousel .slick-dots li button {
  background: var(--primary-color);
}
.ant-carousel .slick-dots li.slick-active button {
  background: var(--dark-blue);
}
.arrows {
  width: 100%;
  display: inline-block;
  position: absolute;
  top: 25%;
}
.prev {
  position: absolute;
  left: 0;
  z-index: 2;
  font-size: 32px;
  cursor: pointer;
  color: var(--text-color);
  opacity: 0.5;
}
.next {
  position: absolute;
  right: 0;
  z-index: 2;
  font-size: 32px;
  cursor: pointer;
  color: var(--text-color);
  opacity: 0.5;
}
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  color: #fff;
  font-size: 5em;
  padding: 50px 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
}
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  width: 100% !important;
  font-size: 32px;
}
.alice-carousel__prev-btn [data-area]::after,
.alice-carousel__next-btn [data-area]::after {
  color: #fff;
  line-height: 0.7;
  -webkit-transition: color 0.2s ease-out;
  -o-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
}
.alice-carousel__prev-btn [data-area]:hover::after,
.alice-carousel__next-btn [data-area]:hover::after {
  color: var(--primary-color);
}
.alice-carousel__next-btn [data-area]::after {
  content: "❯" !important;
  color: var(--primary-color);
  line-height: 0.7;
  -webkit-transition: color 0.2s ease-out;
  -o-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
}
.alice-carousel__prev-btn [data-area]::after {
  content: "❮" !important;
  color: var(--primary-color);
  line-height: 0.7;
  -webkit-transition: color 0.2s ease-out;
  -o-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
}
.alice-carousel__prev-btn {
  text-align: left !important;
}
.alice-carousel__next-btn {
  text-align: right !important;
}
.alice-carousel__prev-btn-item {
  margin-left: -40px;
}
.alice-carousel__next-btn-item {
  margin-right: -40px;
}

.ant-menu-submenu-placement-bottomLeft {
  position: absolute;
  z-index: 1091;
  background: #fff;
  border-radius: 4px;
  width: 90%;
  left: 0 !important;
  right: 0;
  margin: auto;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08),
    0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.ant-carousel .slick-slide img {
  display: block;
  width: 100%;
  height: auto;
}
.cart {
  border: none;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08),
    0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.cart:hover {
  -webkit-box-shadow: 0 7px 14px rgba(0, 0, 0, 0.12),
    0 5px 5px rgba(0, 0, 0, 0.09);
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.12), 0 5px 5px rgba(0, 0, 0, 0.09);
}
.ant-drawer {
  z-index: 2000;
}
.ant-table-tbody > tr > td:last-child {
  word-break: break-word;
}
.customSelect {
  position: relative;
  height: 32px;
  cursor: pointer;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-top: 1.02px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
ul li ul li .ant-select-dropdown-menu-item-group-title {
  display: none;
}
#not-found-page .ant-menu-item-selected {
  background: var(--dark-blue);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.notFound {
  text-align: center;
}
.notFound h1 {
  font-size: 120px;
}
.menu-search {
  display: inline-block;
}
.mobile-search {
  display: none;
}
.mobile-lang {
  display: none;
}
/* MOBILE */
@media only screen and (max-width: 576px) {
  .col-mobile {
    margin-top: 64px;
  }
  .logo {
    margin: auto;
  }
  .logo img {
    width: 200px;
  }
  .langSwitch {
    display: block;
    text-align: center;
  }
  .menu-search {
    display: none;
  }
  .mobile-search {
    display: inline;
    margin-bottom: 4px;
    margin-right: 4px;
    margin-left: 4px;
  }
  .mobile-lang {
    display: block;
  }
  .ant-carousel .slick-slide {
    text-align: center;
    height: 120px;
    line-height: 120px;
    background: #364d79;
    overflow: hidden;
  }
  .headerBreak {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .topMenu {
    display: none;
  }
  .ant-menu-dark.ant-menu-horizontal {
    width: 50px;
    background: var(--dark-blue);
    margin: 0 24px;
    line-height: 32px;
    height: 32px;
  }
  .ant-menu-horizontal span {
    color: var(--dark-blue);
  }
  .headerCartBreak {
    display: block;
  }

  .ant-menu-dark.ant-menu-horizontal:first-child span::before {
    content: "";
    color: var(--primary-color);
    font-size: 24px;
  }
  .ant-menu-dark.ant-menu-horizontal:first-child .ant-menu-submenu-title {
    padding: 0 2px;
  }
  .ant-menu-dark.ant-menu-horizontal:first-child span::after {
    content: "☰";
    color: white;
    font-size: 24px;
  }
  .footerCol {
    border: none;
    border-right: none;
  }
  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-vertical-left > .ant-menu-item,
  .ant-menu-vertical-right > .ant-menu-item,
  .ant-menu-inline > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 50px;
    line-height: 50px;
  }
  .ant-menu-dark .ant-menu-item,
  .ant-menu-dark .ant-menu-item-group-title,
  .ant-menu-dark .ant-menu-item > a {
    color: var(--primary-color);
  }
  .ant-menu-submenu-placement-bottomLeft .ant-menu-item-selected a {
    color: white;
  }
}
@media only screen and (max-width: 820px) {
  .logo {
    margin: auto;
  }
  .logo img {
    width: 200px;
  }
  .headerBreak {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .footerCol {
    border-right: none;
  }
}

#products .ant-skeleton {
  display: flex;
  flex-direction: column;
}

#products .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  width: 100%;
  height: 200px;
  border-radius: 12px;
}
#products .ant-skeleton-header {
  padding-right: 0;
}

#products .ant-skeleton-content {
  display: none;
  flex-direction: column;
}

#products .ant-card-body {
  align-items: flex-start;
}

#products .ant-avatar-image {
  height: 200px;
  width: 100%;
  border-radius: 12px;
}

#products .ant-avatar > img {
  object-fit: contain;
}

.cardImgSkeleton {
  width: 100%;
  object-fit: contain;
}

#products .ant-card-meta-avatar {
  display: inline-flex;
  padding-right: 0;
  height: 200px;
}
.ant-select-selection-selected-value {
  text-overflow: unset;
}

.react-slick * {
  min-height: 0;
  min-width: 0;
}

.react-slick .slick-prev,
.react-slick .slick-next {
  background-color: rgb(187, 184, 184);
  border-radius: 10px;
}
#portal {
  position: absolute;
  z-index: 9999;
}
.slick-dots {
  margin-left: 0;
}
.slick-dots {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}
.slick-thumb li {
  width: 60px;
  height: 45px;
  cursor: pointer;
}
.slick-thumb li img {
  max-width: 100%;
  margin: 0 0 1.45rem;
  padding: 0;
}